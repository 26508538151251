import { Link } from "gatsby";
import Layout from "../../../../src/components/Layout";
import SEO from "../../../../src/components/SEO";
import VegaWrapper from "../../../../src/components/Vega";
import group_check_vega from "../../../../src/posts/group_checks/group_check_vega.json";
import compromise_vega from "../../../../src/posts/group_checks/compromise_vega.json";
import * as React from 'react';
export default {
  Link,
  Layout,
  SEO,
  VegaWrapper,
  group_check_vega,
  compromise_vega,
  React
};