import { Link } from "gatsby";
import Layout from "../../../../src/components/Layout";
import SEO from "../../../../src/components/SEO";
import VegaWrapper from "../../../../src/components/Vega";
import advantage_bonus_vega from "../../../../src/posts/advantage_bonus/advantage_bonus_vega.json";
import * as React from 'react';
export default {
  Link,
  Layout,
  SEO,
  VegaWrapper,
  advantage_bonus_vega,
  React
};