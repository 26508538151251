import { Link } from "gatsby";
import Layout from "../../../../src/components/Layout";
import SEO from "../../../../src/components/SEO";
import VegaWrapper from "../../../../src/components/Vega";
import adv_vs_dis from "../../../../src/posts/advantage_disadvantage_distributions/adv_vs_dis.json";
import cdf from "../../../../src/posts/advantage_disadvantage_distributions/cdf.json";
import * as React from 'react';
export default {
  Link,
  Layout,
  SEO,
  VegaWrapper,
  adv_vs_dis,
  cdf,
  React
};