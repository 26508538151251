import { Link } from "gatsby";
import Layout from "../../../../src/components/Layout";
import SEO from "../../../../src/components/SEO";
import VegaWrapper from "../../../../src/components/Vega";
import probability_fixed_better_vega from "../../../../src/posts/health_roll_vs_fixed/probability_fixed_better_vega.json";
import interval_fixed_vs_rolling_vega from "../../../../src/posts/health_roll_vs_fixed/interval_fixed_vs_rolling_vega.json";
import * as React from 'react';
export default {
  Link,
  Layout,
  SEO,
  VegaWrapper,
  probability_fixed_better_vega,
  interval_fixed_vs_rolling_vega,
  React
};