import { Link } from "gatsby";
import Layout from "../../../../src/components/Layout";
import SEO from "../../../../src/components/SEO";
import VegaWrapper from "../../../../src/components/Vega";
import longsword_vega from "../../../../src/posts/damage_calculations/longsword_vega.json";
import inflict_wounds_vega from "../../../../src/posts/damage_calculations/inflict_wounds_vega.json";
import * as React from 'react';
export default {
  Link,
  Layout,
  SEO,
  VegaWrapper,
  longsword_vega,
  inflict_wounds_vega,
  React
};