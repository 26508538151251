import { Link } from "gatsby";
import Layout from "../../../../src/components/Layout";
import SEO from "../../../../src/components/SEO";
import VegaWrapper from "../../../../src/components/Vega";
import rolling_vs_standard_array from "../../../../src/posts/bad_luck_stat_rolls/rolling_vs_standard_array.png";
import excess_stat_bad_luck_vega from "../../../../src/posts/bad_luck_stat_rolls/excess_stat_bad_luck_vega.json";
import total_stat_rolling_vs_standard_vega from "../../../../src/posts/bad_luck_stat_rolls/total_stat_rolling_vs_standard_vega.json";
import total_stat_threshold_vega from "../../../../src/posts/bad_luck_stat_rolls/total_stat_threshold_vega.json";
import * as React from 'react';
export default {
  Link,
  Layout,
  SEO,
  VegaWrapper,
  rolling_vs_standard_array,
  excess_stat_bad_luck_vega,
  total_stat_rolling_vs_standard_vega,
  total_stat_threshold_vega,
  React
};