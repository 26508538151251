import { Link } from "gatsby";
import Layout from "../../../../src/components/Layout";
import SEO from "../../../../src/components/SEO";
import VegaWrapper from "../../../../src/components/Vega";
import d6_pmf_vega from "../../../../src/posts/exploding_dice/d6_pmf_vega.json";
import exploding_expectations_vega from "../../../../src/posts/exploding_dice/exploding_expectations_vega.json";
import percent_increase_vega from "../../../../src/posts/exploding_dice/percent_increase_vega.json";
import * as React from 'react';
export default {
  Link,
  Layout,
  SEO,
  VegaWrapper,
  d6_pmf_vega,
  exploding_expectations_vega,
  percent_increase_vega,
  React
};